// 单模网关
const data = {
  section1: {
    title: '卫星网关产品',
    desc: '',
  },
  section2: {
    img: require('@/assets/device/0.png'),
    headerTitle: '卫星单模网关',
    title: 'WS-A101卫星单模网关',
    desc: '卫星网关产品包括卫星单模网关及卫星多模边缘计算网关，产品会持续升级换代，新产品发布上市后，并提供相应资料，或参考公司网站信息。',
  },
  section3: {
    feature: [
      // row
      [
        {
          title:
            '向上载荷发送各类传感器数据报文信息，以及接收来自星上载荷的广播、业务数据信息。具备GNSS 模块和轨道预报功能',
          arr: [
            '单载波频域均衡（SCFDE）等系列波形',
            '高性能编译码（Turbo）',
            '分集接收（双接收天线）',
            '传输参数自适应选择（信道学习）',
          ],
        },
      ],
    ],
  },
  section4: {
    column: [
      {
        name: '名称',
        key: 'name',
        with: 400,
      },
      {
        name: '规格',
        key: 'standard',
        // with: 600,
      },
    ],
    tableData: [
      {
        name: '信息速率',
        standard: '200bps800bps',
      },
      {
        name: '报文长度支持',
        standard: '<296Byte@200bps@800bps; 并大于 8 字节',
      },
      {
        name: '供电电压',
        standard: '7.4~15V;',
      },
      {
        name: '功耗',
        standard: '<10uA@休眠',
      },
      {
        name: '存储',
        standard: '32MBit(4096 条报文)',
      },
      {
        name: '天线 ',
        children: [
          {
            name: '通讯天线',
            standard: 'G=2.5dBi',
          },
          {
            name: 'GNSS 天线',
            standard: '供电 3.0V~5.0V，G=28dBi',
          },
        ],
      },
      {
        name: '接口',
        children: [
          {
            name: '数据',
            standard: 'RS232/RS485',
          },
          {
            name: '天线',
            standard: 'SMA-K;特征阻抗: 50Q',
          },
          {
            name: '供电',
            standard: '2.5 线对线连接器',
          },
        ],
      },
      {
        name: '工作温度',
        standard: '-30~65°C',
      },
      {
        name: '防护等级',
        standard: 'IP54',
      },
      {
        name: '机械尺寸',
        standard: '≤118mm x 68mm x26mm',
      },
    ],
  },
}

export default data
