const { VUE_APP_PUBLIC_PATH } = process.env
const path =
    VUE_APP_PUBLIC_PATH === '/'
        ? '/service/'
        : VUE_APP_PUBLIC_PATH + '/service/'

const getRelativePath = (imgPath: string) => {
    return path + imgPath
}

const service: any = {
    0: {
        title: '智慧牧业管理平台',
        price: '价格面议',
        imgUrls: [require('@/assets/service-detail/example1-1.png'), require('@/assets/service-detail/example1-2.png'), require('@/assets/service-detail/example1-3.png')],
        desc: '智慧牧业管理平台系统是一款集成多种环境监测和数据分析功能的农业综合管理工具。系统提供实时室外环境数据，包括温度、湿度、风速、风向、气压和雨量等，帮助用户全面掌握农场环境状况。同时，系统通过物联检测技术，对园区内的温度、湿度、光照和二氧化碳浓度进行监测，并提供环境评分。此外，系统还能分析空气、土壤和光照等数据，生成趋势图，为农业生产提供科学依据。告警信息模块则能实时提醒用户关注异常情况，确保及时采取措施。',
        fontFamily: '微软雅黑',
        fontSize: ['20', '16', '14', '12'],
        fontSizeBold: ['24', '18', '16'],
        mainColor: '#1ea2ff',
        extraColors: ['#51f7fe', '#ffe388'],
        media: 'pc',
        homeImg: require('@/assets/service/example1.png'),
        detailImgs: ['1.png', '2.png']
    },
    1: {
        title: '智慧农业管理平台',
        price: '价格面议',
        imgUrls: [require('@/assets/service-detail/example2-1.png'), require('@/assets/service-detail/example2-2.png'), require('@/assets/service-detail/example2-3.png')],
        desc: '智慧农业管理平台是一款集成多种环境监测和数据分析功能的农业综合管理工具。系统提供实时室外环境数据，包括温度、湿度、风速、风向、气压和雨量等，帮助用户全面掌握农场环境状况。同时，系统通过物联检测技术，对园区内的温度、湿度、光照和二氧化碳浓度进行监测，并提供环境评分。此外，系统还能分析空气、土壤和光照等数据，生成趋势图，为农业生产提供科学依据。告警信息模块则能实时提醒用户关注异常情况，确保及时采取措施。',
        fontFamily: '微软雅黑',
        fontSize: ['20', '16', '14', '12'],
        fontSizeBold: ['24', '18', '16'],
        mainColor: '#1ea2ff',
        extraColors: ['#51f7fe', '#ffe388'],
        media: 'pc',
        homeImg: require('@/assets/service/example2.png'),
        detailImgs: ['1.png', '2.png']
    },
    2: {
        title: '灾害监测预警平台',
        price: '价格面议',
        imgUrls: [require('@/assets/service-detail/example3-1.png'), require('@/assets/service-detail/example3-2.png'), require('@/assets/service-detail/example3-3.png')],
        desc: '灾害监测预警平台基于智能传感器、北斗导航、卫星遥感、物联网、云计算和大数据技术，提供实时监控、预警预测、数据管理和决策辅助功能。利用高分辨率卫星遥感影像和地面传感器数据，实现多源数据融合，构建精准的地灾监测平台。系统包括地面传感器、卫星网关、遥感和北斗卫星，以及移动客户端，通过实时监测和数据传输，接入统一监测平台。方案能够采集、分析监测数据，结合遥感数据进行风险识别，实现灾害隐患监测、风险评价和预警，为地灾管理提供科学依据。',
        fontFamily: '微软雅黑',
        fontSize: ['20', '16', '14', '12'],
        fontSizeBold: ['24', '18', '16'],
        mainColor: '#1ea2ff',
        extraColors: ['#51f7fe', '#ffe388'],
        media: 'pc',
        homeImg: require('@/assets/service/example3.png'),
        detailImgs: ['1.png', '2.png']
    },
    3: {
        title: '智慧船舶管理平台',
        price: '价格面议',
        imgUrls: [require('@/assets/service-detail/example4-1.png'), require('@/assets/service-detail/example4-2.png'), require('@/assets/service-detail/example4-3.png'), require('@/assets/service-detail/example4-4.png')],
        desc: '智慧船舶管理平台是一款专为渔业船舶设计的综合性管理工具。该平台集成了船舶定位、船舶列表、套餐管理、流量池管理和用户管理等功能。通过船舶定位功能，用户可以实时追踪船舶的位置、速度、航向以及经纬度信息，确保船舶的安全运营。平台还提供了船舶的详细信息查询和历史轨迹回放功能，帮助用户对船舶进行有效的监控和管理。此外，平台还支持用户管理，方便对不同用户进行权限设置和操作记录。智慧渔业船舶管理平台通过融合通信等技术手段，为渔业船舶的高效、安全运营提供了有力支持。',
        fontFamily: '微软雅黑',
        fontSize: ['20', '16', '14', '12'],
        fontSizeBold: ['24', '18', '16'],
        mainColor: '#1ea2ff',
        extraColors: ['#51f7fe', '#ffe388'],
        media: 'pc',
        homeImg: require('@/assets/service/example4.png'),
        detailImgs: ['1.png', '2.png']
    },
    4: {
        title: '海豚监测系统',
        price: '价格面议',
        imgUrls: [require('@/assets/service-detail/example5-1.png'), require('@/assets/service-detail/example5-2.png'), require('@/assets/service-detail/example5-3.png')],
        desc: '海豚监测系统是一款专为海洋生物保护设计的可视化管理工具。系统提供实时监测点数据，包括监测点的统计图表和历史数据记录，便于用户追踪和分析海豚的活动模式。通过直观的柱状图展示最近一个月的监测数据，用户可以快速识别海豚出现的频率和趋势。此外，系统还列出了各个监测站点的详细数据，包括已审核和未审核的信息，确保数据的准确性和及时性。该系统不仅有助于科研人员研究海豚行为，也为海洋保护工作提供了重要的数据支持。',
        fontFamily: '微软雅黑',
        fontSize: ['20', '16', '14', '12'],
        fontSizeBold: ['24', '18', '16'],
        mainColor: '#1a6b88',
        extraColors: ['#54cee6'],
        media: 'pc',
        homeImg: require('@/assets/service/example5.png'),
        detailImgs: ['1.png', '2.png']
    },
    5: {
        title: '牦牛监测小程序',
        price: '价格面议',
        imgUrls: [require('@/assets/service-detail/example6-1.png'), require('@/assets/service-detail/example6-2.png'), require('@/assets/service-detail/example6-3.png'), require('@/assets/service-detail/example6-4.png')],
        desc: '牦牛监测小程序是一款专为牦牛养殖设计的智能管理系统，主要功能包括牦牛定位、牦牛报到、告警消息和用户管理。通过GPS定位技术，用户可以实时追踪牦牛的位置和移动轨迹，确保牲畜安全。牦牛报到功能允许用户记录和查看牦牛的报到情况，包括已报到和未报到的牦牛数量及详细信息。告警消息功能则提供实时告警通知，如低电压、步数异常等，帮助用户及时采取措施。此外，用户管理功能使得操作更加便捷和安全。',
        fontFamily: '微软雅黑',
        fontSize: ['36', '32', '28', '24'],
        fontSizeBold: ['64', '36', '32'],
        mainColor: '#255dfa',
        extraColors: ['#f4792c', '#ef5460', '#40dd73', '#3ca2f5'],
        media: 'mobile',
        homeImg: require('@/assets/service/example6.png'),
        detailImgs: ['1.png', '2.png']
    },
    6: {
        title: '森林公园管理小程序',
        price: '价格面议',
        imgUrls: [require('@/assets/service-detail/example7-1.png'), require('@/assets/service-detail/example7-2.png'), require('@/assets/service-detail/example7-3.png'), require('@/assets/service-detail/example7-4.png')],
        desc: '森林公园管理小程序致力于为游客们提供全方位、便捷的游园体验，让每一位踏入公园的游客都能轻松探索自然之美。小程序提供实时天气查询，方便游客提前做好出行准备。还设有园内设施报修与投诉建议入口，游客发现问题或有任何想法，都能及时反馈，助力公园提升服务质量。',
        fontFamily: '微软雅黑',
        fontSize: ['36', '32', '28', '24'],
        fontSizeBold: ['64', '36', '32'],
        mainColor: '#22a267',
        extraColors: ['#da7d44', '#00a999', '#3b8adc', '#8533c1'],
        media: 'mobile',
        homeImg: require('@/assets/service/example7.png'),
        detailImgs: ['1.png', '2.png']
    },
    7: {
        title: '卫星过境监测系统',
        price: '价格面议',
        imgUrls: [require('@/assets/service-detail/example8-1.png'), require('@/assets/service-detail/example8-2.png'), require('@/assets/service-detail/example8-3.png'), require('@/assets/service-detail/example8-4.png')],
        desc: ' 卫星过境监测系统是一款先进的卫星监测系统，提供全面的卫星数据概览、在轨卫星数量和运行时间统计。系统能够实时跟踪卫星过境情况，预测卫星过境时间，并记录详细的过境数据。通过直观的图表展示在轨卫星数量和运行时间的趋势，帮助用户了解卫星运行状态。此外，系统还监测卫星接入设备，包括单模网关、多模网关和Lora节点的数量，确保设备的有效管理和控制。该系统是卫星监测和管理的理想选择，为卫星运营提供强有力的数据支持和决策依据。',
        fontFamily: '微软雅黑',
        fontSize: ['20', '16', '14', '12'],
        fontSizeBold: ['24', '18', '16'],
        mainColor: '#1ea2ff',
        extraColors: ['#ffbb00', '#00ffbf', '#a0f866'],
        media: 'pc',
        homeImg: require('@/assets/service/example8.png'),
        detailImgs: ['1.png', '2.png']
    },
    8: {
        title: 'SMT车间信息管理平台',
        price: '价格面议',
        imgUrls: [require('@/assets/service-detail/example9-1.png'), require('@/assets/service-detail/example9-2.png'), require('@/assets/service-detail/example9-3.png')],
        desc: 'SMT产线信息管理平台是一款用于监控和优化表面贴装技术（SMT）生产线性能的管理平台。该平台提供实时的生产计划状态、完工率、一次合格率等关键指标，并通过图表形式展示炉温曲线，帮助用户监控生产过程中的温度变化。系统详细列出了当日生产计划，包括产品名称、规格型号、完工计划和时间等信息，便于生产管理和调度。此外，系统还显示当前工单信息和一次合格率，为质量控制提供数据支持。通过集成的设备布局图，用户可以直观了解生产线的设备状态和运行情况。',
        fontFamily: '微软雅黑',
        fontSize: ['36', '32', '28', '24'],
        fontSizeBold: ['64', '36', '32'],
        mainColor: '#22a267',
        extraColors: ['#da7d44', '#00a999', '#3b8adc', '#8533c1'],
        media: 'mobile',
        homeImg: require('@/assets/service/example9.png'),
        detailImgs: ['1.png', '2.png']
    },
}

export default service