// 多模网关
const data = {
  section1: {
    title: '卫星网关产品',
  },
  section2: {
    img: require('@/assets/device/1.png'),
    headerTitle: '卫星多模边缘计算网关',
    title: 'WS-A201卫星多模边缘计算网关',
    desc: `WS-A201卫星多模边缘计算网关其核心是基于卫星通信和地面5G蜂窝网、以及LoRaWAN网关的一体化物联网智能终端。产品内置GEO卫星物联网模块、5G模块（或4G模块）、斯北图SPT-DCS/LR卫星（LEO）物联网模块、地面LoRa网关（支持LoRaWAN协议）。产品具有卫星过境轨道预报和数据存储管理功能。
    <br/><br/>
    产品可以灵活满足不同地区的多行业的不同数据量数据采集和传输需求，具有传感器接入灵活（支持ModBus标准传感器）。产品可以根据需要自由切换数据回传的方式；可以对传感器数据进行加密传输，保证用户数据的安全性。产品可应用于水利，地质灾害、电力输电线，海洋，林牧业等对卫星有需求的物联网场景，也可作为备用应急通信方式应用在有蜂窝网的区域。产品及数据平台化管理，可以方便用户快速搭建不同的场景应用。`,
  },
  section3: {
    feature: [
      // row
      [
        {
          title: '',
          arr: ['支持通过地球GEO卫星进行数据随时回传和反向数据传输控制'],
        },
        {
          title: '',
          arr: [
            '支持通过地球LEO卫星进行数据随时回传和反向数据传输，具有传输功耗低优点',
          ],
        },
        {
          title: '',
          arr: [
            '支持接收星上载荷下发的广播/业务数据，对终端的卫星星历进行更新',
          ],
        },
      ],
      [
        {
          title: '',
          arr: ['支持RS485/RS232连接传感器进行有线数据采集'],
        },
        {
          title: '',
          arr: ['融合LORA网关，对5KM范围内的传感器进行数据无线采集传输'],
        },
        {
          title: '',
          arr: ['支持MODBUS标准协议传感器直连，无需中间单元可快速搭建解决方案'],
        },
      ],
      [
        {
          title: '',
          arr: ['集成5G全网通模块，支持语音视频数据传输'],
        },
      ],
    ],
  },
  section4: {
    column: [
      {
        name: '名称',
        key: 'name',
        with: 400,
      },
      {
        name: '规格',
        key: 'standard',
        // with: 600,
      },
    ],
    tableData: [
      {
        name: '尺寸',
        standard: '135mm*135mm*50mm',
      },
      {
        name: '按键',
        standard: '复位按键',
      },
      {
        name: '接口',
        standard: '电源，RS485或RS232',
      },
      {
        name: '程序升级',
        standard: '通过蓝牙升级程序',
      },
      {
        name: '指示灯',
        standard: '电源/卫星模组（天通-红，DCS-绿，TQ-蓝），5G，lora网络指示灯',
      },
      {
        name: '供电',
        standard: '+7~15V',
      },
      {
        name: '功耗',
        standard: '常值<2W，峰值<10W',
      },
      {
        name: '网络模式',
        standard: `天通（9.6kbps,最大1000Byte）、
      DCS（0.2kbps，0.8kbps，20kbps， 最大720Byte）、TQ(0.268kbps，8.75kbps)、5G全网通网络
      `,
      },
      {
        name: '天线',
        standard: `外置天线：卫星天线、蜂窝网天线、LORA天线、GNSS天线；
      内置天线：wifi、BT天线
      `,
      },
      {
        name: '系统',
        standard: 'RTOS+Linux',
      },
      {
        name: '调试口',
        standard: '预留debug口',
      },
      {
        name: '温度',
        standard: `工作温度：-25~65℃
      存储温度：-40~80℃
      `,
      },
      {
        name: '重量',
        standard: '<500g',
      },
      {
        name: '防护等级',
        standard: 'IP67',
      },
    ],
  },
}

export default data
