// MESH自组网设备
// WS-BSDR402手持型
const data = {
  section1: {
    title: 'MESH自组网设备',
    desc: '',
  },
  section2: {
    img: require('@/assets/device/4.png'),
    headerTitle: '宽带手持型',
    title: 'WS-BSDR402手持型',
    desc: `WS-BSDR402手持型终端是我司基于软件通信体系结构（SCA）及多跳自组网技术（MESH）研制的无线通信终端。我司开发并深度优化了物理层波形算法，使产品在接收灵敏度、作用距离、移动性和吞吐率稳定性等方面有了明显的改善。设备以高性能可编程处理器为核心，支持动态加载我司全系列宽/窄带、抗干扰等通信波形，可为用户在复杂环境下迅速搭建可靠的无线链路，为各种业务（如：高清视频、语音、IP数据传输等）提供快速灵活的解决方案。
    <br/><br/>
    该产品适用于移动车辆和航船等装备，实现车与车、车与单兵、单兵与单兵间无间断通信。
    `,
  },
  section3: {
    feature: [
      // row
      [
        {
          title: '高性能传输技术',
          arr: [
            '单载波频域均衡（SCFDE）等系列波形',
            '高性能编译码（Turbo）',
            '分集接收（双接收天线）',
            '传输参数自适应选择（信道学习）',
          ],
        },
        {
          title: '软件通信体系结构（SCA）',
          arr: ['支持多波形加/卸载', '支持第三方用户波形开发和移植'],
        },
        {
          title: '多跳自组网（MESH）技术',
          arr: ['自动组网，无需人工干预', '组网灵活，支持任意网络拓'],
        },
      ],
      // row
      [
        {
          title: '便携和易用性',
          arr: ['即开即用', '支持手机及Web等配置功能，可远程管理'],
        },
      ],
    ],
  },
  section4: {
    column: [
      {
        name: '名称',
        key: 'name',
        with: 400,
      },
      {
        name: '规格',
        key: 'standard',
        // with: 600,
      },
    ],
    tableData: [
      {
        name: '无线特性',
        children: [
          {
            name: '频率范围',
            standard: '200MHz～2000MHz(支持定制)',
          },
          {
            name: '带宽',
            standard: '0.25/0.5/1/2/4MHz',
          },
          {
            name: '调制方式',
            standard: 'GMSK',
          },
          {
            name: '传输方式',
            standard: '1发1收',
          },
          {
            name: '编译码',
            standard: 'Turbo(≈2/3效率)',
          },
          {
            name: '传输速率',
            standard: '0.125/0.25/0.5/1/2Mbps',
          },
          {
            name: '接收灵敏度',
            standard: '-106dBm@BW=0.25MHz',
          },
          {
            name: '传输距离',
            standard: `典型场景：城区复杂路面500m 
            郊区开阔路面1km
            `,
          },
          {
            name: '发射功率',
            standard: '≤0.5W',
          },
        ],
      },
      {
        name: '组网功能',
        children: [
          {
            name: '组网能力',
            standard: '支持16节点以上',
          },
          {
            name: '组网跳数',
            standard: '＞8跳(链式网)',
          },
          {
            name: '组网时间',
            standard: '系统启动后5s入网',
          },
          {
            name: '网络拓扑',
            standard: '有/无中心网络、星型网、链式网、网格网等',
          },
        ],
      },
      {
        name: '用户接口',
        children: [
          {
            name: '射频接口',
            standard: '内置，发射/接收共用天线',
          },
          {
            name: 'WIFI/蓝牙接口',
            standard: '内置，WIFI/蓝牙共用天线 ，支持蓝牙语音',
          },
          {
            name: '充电+数据接口',
            standard: 'TYPE-C',
          },
          {
            name: '音频口',
            standard: '3.5mm耳机插孔',
          },
        ],
      },
      {
        name: '物理特性',
        children: [
          {
            name: '外形尺寸',
            standard: '＜145×55×22mm',
          },
          {
            name: '整体重量',
            standard: '＜300g',
          },
        ],
      },
      {
        name: '工作时长',
        children: [
          {
            name: '工作时长 ',
            standard: `组网，偶有数据收发时不小于10H；
            0.5W长发时不小于4H；
            `,
          },
        ],
      },
      {
        name: '环境适应性',
        children: [
          {
            name: '防护等级',
            standard: '不低于IP54',
          },
          {
            name: '工作温度',
            standard: '-20℃～+50℃',
          },
          {
            name: '存储温度',
            standard: '-40℃～+80℃',
          },
        ],
      },
      {
        name: '其他',
        children: [
          {
            name: '系统升级',
            standard: '支持一键升级、远程升级',
          },
          {
            name: '控制功能',
            standard: '支持网络管理系统平台',
          },
          {
            name: '显示功能',
            standard: `单色显示屏（设计功能键可进行参数设置）、
            链路指标灯（反馈链路连接质量）
            `,
          },
          {
            name: '抗干扰功能',
            standard: `干扰检测、一键换频`,
          },
        ],
      },
    ],
  },
}

export default data
