const { VUE_APP_PUBLIC_PATH } = process.env
const path =
  VUE_APP_PUBLIC_PATH === '/'
    ? '/software/'
    : VUE_APP_PUBLIC_PATH + '/software/'

const getRelativePath = (imgPath: string) => {
  return path + imgPath
}

const software: any = {
  0: {
    title: '天数AIoT平台',
    subTitle:
      '平台依托卫星通信能力，支持海量设备连接，可实现设备数据上云；支持云端指令下发至设备端，可实现设备远程控制。',
    desc: '天数卫星AloT平台依托卫星通信能力，利用先进的卫星通导遥技术、云计算技术，向下支持海量设备连接，可实现设备数据上云；向下支持云端指令下发至设备端，可实现设备远程控制。平台可为智慧水利、智慧农业、智慧畜牧、智慧应急、智慧农业、智慧电网等行业提供定制化解决方案。',
    class: 'tianshu',
    bg: getRelativePath('bg-0.png'),
    loginUrl: 'https://tianshu.wstaiot.com',
    headerDesc:
      '平台依托卫星通信能力，支持海量设备连接，可实现设备数据上云；支持云端指令下发至设备端，可实现设备远程控制。',
    homeImg: require('@/assets/home/service-bak1.png'),
    homeIcon: require('@/assets/home/service-img1.png'),
  },
  1: {
    title: 'LEO物联星座云监测控制平台',
    subTitle:
      '平台融合卫星及网关设备数据资源，辅助管理者全面掌控卫星运行态势，实现综合运营态势一屏掌握。',
    desc: 'LEO物联星座云监测控制平台融合卫星及网关设备数据资源，对微信运行状态、通信数据、位置信息、运行空间等关键指标进行综合监测分析，辅助管理者全面掌控卫星运行态势，实现综合运营态势一屏掌握。预计2023年底在轨卫星27颗，2025年前实现48颗卫星组网，为地面物联网和垂直行业提供全球信息传输通道，构建”天、空、地“一体的卫星AIoT平台。',
    class: 'big-screen',
    bg: getRelativePath('bg-1.png'),
    loginUrl: 'https://sea.wstaiot.com',
    headerDesc:
      '平台融合卫星及网关设备数据资源，辅助管理者全面掌控卫星运行态势，实现综合运营态势一屏掌握。',
    homeImg: require('@/assets/home/service-bak2.png'),
    homeIcon: require('@/assets/home/service-img2.png'),
  },
  2: {
    title: '天数卫星遥测数据共享平台',
    subTitle:
      '平台为用户提供遥感大数据综合服务,满足其对于卫星遥感数据的需求,促进间时空大数据信息交换和资源共享。',
    desc: '天数卫星遥测数据共享平台通过空间地理信息综合服务为用户提供遥感大数据综合服务,满足其对于卫星遥感数据的需求,促进间时空大数据信息交换和资源共享,提升遥感数据在地理信息产业中的应用宽度与深度。经过脱密处理后的遥感数据为企业和大众提供服务,从而推动空间地理信息规模化和产业化的发展,实现"互联网+遥感","数据+应用"一体化支撑。',
    class: 'satview',
    bg: getRelativePath('bg-2.png'),
    loginUrl: '',
    headerDesc:
      '平台为用户提供遥感大数据综合服务,满足其对于卫星遥感数据的需求,促进间时空大数据信息交换和资源共享。',
    homeImg: require('@/assets/home/service-bak3.png'),
    homeIcon: require('@/assets/home/service-img3.png'),
  },
  3: {
    title: '卫星运营平台',
    subTitle:
      '平台融合了业务管理、网络管理、客户管理、计费管理、账务管理功能，为行业客户提供一体的、定制化的综合业务运营管理平台。',
    desc: '卫星运营平台是一种现代化的卫星运营管理系统，通过建立卫星运营中心和卫星信息网络，对卫星的发射、控制、监测和维护实行全面的监控和管理。',
    class: 'operation',
    bg: getRelativePath('bg-3.png'),
    loginUrl: 'https://somdc.wstaiot.com',
    headerDesc:
      '平台融合了业务管理、网络管理、客户管理、计费管理、账务管理功能，为行业客户提供一体的、定制化的综合业务运营管理平台。',
    homeImg: require('@/assets/home/service-bak4.png'),
    homeIcon: require('@/assets/home/service-img4.png'),
  },
}

export default software
