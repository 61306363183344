// MESH自组网设备
// WS-BSDR801车载型设备
const data = {
  section1: {
    title: 'MESH自组网设备',
    desc: '',
  },
  section2: {
    img: require('@/assets/device/9.png'),
    headerTitle: '车载型设备',
    title: 'WS-BSDR801车载型设备',
    desc: `WS-BSDR801车载型设备是我司基于软件通信体系结构（SCA）及多跳自组网技术（MESH）研制的无线通信终端。我司开发并深度优化了物理层波形算法，使产品在接收灵敏度、作用距离、移动性和吞吐率稳定性等方面有了明显的改善。设备以高性能可编程处理器为核心，支持动态加载我司全系列宽/窄带、抗干扰等通信波形，可为用户在复杂环境下迅速搭建可靠的无线链路，为各种业务（如：高清视频、语音、IP数据传输等）提供快速灵活的解决方案。
    `,
  },
  section3: {
    feature: [
      // row
      [
        {
          title: '高性能传输技术',
          arr: [
            '单载波频域均衡（SCFDE）等系列波形',
            '高性能编译码（Turbo）',
            '分集接收（双接收天线）',
            '传输参数自适应选择（信道学习）',
          ],
        },
        {
          title: '软件通信体系结构（SCA)',
          arr: ['支持多波形加/卸载', '支持第三方用户波形开发和移植'],
        },
        {
          title: '故障管理功能',
          arr: ['自检、运行监视、故障诊断与隔离', '支持无人值守'],
        },
      ],
      // row
      [
        {
          title: '多跳自组网（MESH）技术',
          arr: ['自动组网，无需人工干预', '组网灵活，支持任意网络拓扑'],
        },
        {
          title: '便携易用',
          arr: ['即开即用', '支持手机、Web等配置功能，可远程管理'],
        },
      ],
    ],
  },
  section4: {
    column: [
      {
        name: '名称',
        key: 'name',
        with: 400,
      },
      {
        name: '规格',
        key: 'standard',
        // with: 600,
      },
    ],
    tableData: [
      {
        name: '无线特性',
        children: [
          {
            name: '频率范围',
            standard: '1420～1470MHz',
          },
          {
            name: '带宽',
            standard: '1/2/5/10/20/40MHz可配置',
          },
          {
            name: '调制方式',
            standard: 'SCFDE + QPSK/16QAM/64QAM(自适应)',
          },
          {
            name: '传输方式',
            standard: '1发2收',
          },
          {
            name: '编译码',
            standard: 'Turbo码(编码效率1/3、1/2、2/3自适应)',
          },
          {
            name: '传输速率',
            standard: `峰值空口速率108Mbps@40MHz
            峰值业务速率72Mbps@40MHz
            `,
          },
          {
            name: '接收灵敏度',
            standard: '-110dBm@1MHz',
          },
          {
            name: '传输距离',
            standard: `典型场景：城区复杂路面4km 
            郊区开阔路面8km
            `,
          },
          {
            name: '发射功率',
            standard: '最大20W',
          },
        ],
      },
      {
        name: '组网功能',
        children: [
          {
            name: '组网能力',
            standard: '支持32节点以上',
          },
          {
            name: '组网跳数',
            standard: '>8跳',
          },
          {
            name: '组网时间',
            standard: '冷启动25秒入网，热启动5秒入网',
          },
          {
            name: '网络拓扑',
            standard: '无中心网络、星型网、链式网、网格网等',
          },
          {
            name: '网络透传',
            standard: '支持网络透传功能',
          },
        ],
      },
      {
        name: '用户接口',
        children: [
          {
            name: '射频接口',
            standard: `ANT1：N，发射/接收共用天线
            ANT2：N，接收天线
            `,
          },
          {
            name: 'WIFI/蓝牙接口',
            standard: `SMA，2.4G/5.8G双频天线`,
          },
          {
            name: 'GPS/北斗接口',
            standard: 'SMA，GPS/北斗天线',
          },
          {
            name: '网口+串口接口',
            standard: '航插（企标），百兆 ，RS232',
          },
          {
            name: '音频接口',
            standard: '航插（企标），手咪',
          },
          {
            name: 'SDI接口',
            standard: '航插（企标），SDI',
          },
          {
            name: '供电接口',
            standard: '航插（企标），48V/3A',
          },
        ],
      },
      {
        name: '物理特性',
        children: [
          {
            name: '外形尺寸',
            standard: '≤485mm×460mm×90mm（2U）',
          },
          {
            name: '整体重量',
            standard: '≤8.5kg',
          },
        ],
      },
      {
        name: '整机功耗',
        children: [
          {
            name: '整机功耗',
            standard: '≤90W@20W输出',
          },
        ],
      },
      {
        name: '环境适应性',
        children: [
          {
            name: '防尘等级',
            standard: '二级防尘',
          },
          {
            name: '工作温度',
            standard: '-40℃～+60℃（WIFI模组只能到-20℃）',
          },
          {
            name: '存储温度',
            standard: '-55℃～+125℃',
          },
        ],
      },
      {
        name: '其它',
        children: [
          {
            name: '系统升级',
            standard: '支持一键升级、远程升级',
          },
          {
            name: '控制功能',
            standard: '支持网络管理系统平台',
          },
        ],
      },
    ],
  },
}

export default data
