// MESH自组网设备
// WS-BSDR301指挥箱
const data = {
  section1: {
    title: 'MESH自组网设备',
    desc: '',
  },
  section2: {
    img: require('@/assets/device/7.png'),
    headerTitle: '指挥箱',
    title: 'S-BSDR301指挥箱',
    desc: `WS-BSDR301指挥箱是一款集MESH、音视频管理调度、实时显示以及电池于一体的便携型无线宽带MESH产品。该设备基于SCFDE技术和MESH多跳自组网技术研制，能以应急便携箱的产品形态使用，并实现与不同产品形态的自由组网。产品配备高性能处理器，可供用户在复杂环境下迅速搭建可靠的无线链路，对各种业务需求（如：高清视频、语音对讲、IP数据传输等）提供快速灵活的指挥管理。`,
  },
  section3: {
    feature: [
      // row
      [
        {
          title: '高性能传输技术',
          arr: [
            '单载波频域均衡（SCFDE）等系列波形',
            '高性能编译码（Turbo）',
            '分集接收（双接收天线）',
            '传输参数自适应选择（信道学习）',
          ],
        },
        {
          title: '软件通信体系结构（SCA)',
          arr: ['支持多波形加/卸载', '支持第三方用户波形开发和移植'],
        },
        {
          title: '多跳自组网（MESH）技术',
          arr: [
            '自动组网，自动计算网内路由，无需人工干预，有效节省人力物力',
            '支持64节点自组网，自动多跳传输，实现区域无缝覆盖',
          ],
        },
      ],
      // row
      [
        {
          title: '便携和易用性',
          arr: ['机即用，便携式移动', '支持手机及Web等配置功能，可远程管理'],
        },
        {
          title: '定制化功能',
          arr: [
            '发射功率2W，支持定制',
            '工作频段1420～1470MHz可定制',
            '信道带宽1/2/5/10/20/40MHz可调',
          ],
        },
      ],
    ],
  },
  section4: {
    column: [
      {
        name: '名称',
        key: 'name',
        with: 400,
      },
      {
        name: '规格',
        key: 'standard',
        // with: 600,
      },
    ],
    tableData: [
      {
        name: '无线特性',
        children: [
          {
            name: '频率范围',
            standard: '1420～1470MHz',
          },
          {
            name: '传输速率',
            standard: `峰值空口速率108Mbps@40MHz
            峰值业务速率72Mbps@40MHz
            `,
          },
          {
            name: '数字灵敏度',
            standard: '-110dBm@1MHz',
          },
          {
            name: '传输距离',
            standard: '＞10km（视距）',
          },
        ],
      },
      {
        name: '组网功能',
        children: [
          {
            name: '组网能力',
            standard: '支持64节点以上',
          },
          {
            name: '网络拓扑',
            standard: '支持星型网、链式网、网格网等',
          },
          {
            name: '网络透传',
            standard: '支持网络透传功能',
          },
        ],
      },
      {
        name: '音视频功能',
        children: [
          {
            name: '支持接口',
            standard: '支持IP输入，HDMI、VGA输出',
          },
          {
            name: '码流支持',
            standard: '支持1080P高清视频输出',
          },
          {
            name: '音频接口',
            standard: '手咪航插接口',
          },
          {
            name: '音频格式',
            standard: '音频MP3,AAC',
          },
        ],
      },
      {
        name: '管理平台',
        children: [
          {
            name: '操作系统',
            standard: 'Windows 7/64位',
          },
          {
            name: 'CPU',
            standard: '酷睿i7 CPU',
          },
          {
            name: '存储系统',
            standard: '8GB/256GB SSD',
          },
          {
            name: '屏幕显示',
            standard: '5.6英寸高清显示屏',
          },
          {
            name: '输入键盘',
            standard: '工业防水键盘',
          },
        ],
      },
      {
        name: '系统参数',
        children: [
          {
            name: '外设接口',
            standard: 'USB、网口、HDMI、Audio',
          },
          {
            name: '控制功能',
            standard: '支持网络管理系统、便携式管理平台的接入',
          },
          {
            name: '其余功能',
            standard: 'GPS/北斗、WiFi',
          },
          {
            name: '功耗',
            standard: '≤70W（2W）',
          },
        ],
      },
      {
        name: '物理特性',
        children: [
          {
            name: '工作温度',
            standard: '-20℃～50℃',
          },
          {
            name: '防护等级',
            standard: 'IP65',
          },
          {
            name: '外形尺寸',
            standard: '406mm×330mm×174mm ',
          },
          {
            name: '整体重量',
            standard: '整机约7kg',
          },
        ],
      },
    ],
  },
}

export default data
