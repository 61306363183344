// MESH自组网设备
// WS-BSDR101背负型设备
const data = {
  section1: {
    title: 'MESH自组网设备',
    desc: '',
  },
  section2: {
    img: require('@/assets/device/6.png'),
    headerTitle: '布控球',
    title: 'WS-BSDR201布控球',
    desc: `WS-BSDR201布控球是为室外高清视频监控应用量身打造的一款无线宽带自组网设备。该产品集成无线自组网模块与高清红外摄像头，可工作在没有4G信号以及其他无网络覆盖的区域。球机内含1/2.8"高性能逐行扫描CMOS传感器和高性能多媒体处理器，嵌入式Linux系统、H.265编码模块等，是一款含视频采集、图像处理、无线传输等功能于一体的高集成型网络摄像球机。
    <br/><br/>
    该产品自带无中心自组网通信功能，能够与我司其余系列产品快速组成无中心网络，可广泛用于应急布控，无人值守，智慧营区等场景。
    `,
  },
  section3: {
    feature: [
      // row
      [
        {
          title: '高性能传输技术',
          arr: [
            '单载波频域均衡（SCFDE）等系列波形',
            '高性能编译码（Turbo）',
            '分集接收（双接收天线）',
            '传输参数自适应选择（信道学习）',
          ],
        },
        {
          title: '便携易用',
          arr: [
            '支持背负或车载使用',
            '开机即用',
            '电池可拆卸',
            '支持手机、Web等配置功能，可远程管理',
          ],
        },
        {
          title: '故障管理功能',
          arr: ['自检、运行监视、故障诊断与隔离', '支持无人值守'],
        },
      ],
      // row
      [
        {
          title: '多跳自组网（MESH）技术',
          arr: ['自动组网，无需人工干预', '组网灵活，支持任意网络拓扑理'],
        },
        {
          title: '软件通信体系结构（SCA）',
          arr: ['支持多波形加/卸载', '支持第三方用户波形开发和移植'],
        },
        {
          title: '集成高清红外球机',
          arr: [
            '高清PTZ球机，水平360°，垂直105°',
            '日夜转换模式，ICR红外滤片式',
            '支持最高30X光学变焦',
          ],
        },
      ],
    ],
  },
  section4: {
    column: [
      {
        name: '名称',
        key: 'name',
        with: 400,
      },
      {
        name: '规格',
        key: 'standard',
        // with: 600,
      },
    ],
    tableData: [
      {
        name: '无线特性',
        children: [
          {
            name: '频率范围',
            standard: '1420～1470MHz(支持定制)',
          },
          {
            name: '带宽',
            standard: '1/2/5/10/20/40MHz可配置',
          },
          {
            name: '调制方式',
            standard: 'SCFDE + QPSK/16QAM/64QAM(自适应)',
          },
          {
            name: '传输方式',
            standard: '1发2收',
          },
          {
            name: '编译码',
            standard: 'Turbo码(编码效率36%/54%/72%自适应)',
          },
          {
            name: '传输速率',
            standard: `峰值空口速率108Mbps@40MHz
            峰值业务速率72Mbps@40MHz
            `,
          },
          {
            name: '接收灵敏度',
            standard: '-110dBm@1MHz',
          },
          {
            name: '传输距离',
            standard: `典型场景：城区复杂路面2km 
            郊区开阔路面4km
            `,
          },
          {
            name: '发射功率',
            standard: `2W(支持定制)`,
          },
        ],
      },
      {
        name: '组网功能',
        children: [
          {
            name: '组网能力',
            standard: '支持64节点以上',
          },
          {
            name: '组网跳数',
            standard: '>8跳',
          },
          {
            name: '组网时间',
            standard: '冷启动25秒入网，热启动5秒入网',
          },
          {
            name: '网络拓扑',
            standard: '无中心网络、星型网、链式网、网格网等',
          },
          {
            name: '网络透传',
            standard: '支持网络透传功能',
          },
        ],
      },
      {
        name: '系统参数',
        children: [
          {
            name: '外设接口',
            standard: '网口、音频',
          },
          {
            name: '控制功能',
            standard: '支持网络管理系统平台',
          },
          {
            name: '系统升级',
            standard: '支持一键升级，远程升级',
          },
          {
            name: '功耗',
            standard: '≤30W（2W）',
          },
          {
            name: '其余功能',
            standard: 'GPS/北斗、WiFi',
          },
        ],
      },
      {
        name: '球机规格',
        children: [
          {
            name: '视频编码',
            standard: 'H.265 Main Profile',
          },
          {
            name: '日夜转换模式',
            standard: 'ICR红外滤片式',
          },
          {
            name: '分辨率',
            standard: '1920×1080/1280×720 25fps/30fps',
          },
          {
            name: '光学变焦',
            standard: '30X',
          },
          {
            name: '红外',
            standard: '60-80米',
          },
          {
            name: '数字变倍',
            standard: '12倍',
          },
          {
            name: '最小照度',
            standard: `彩色：0.05Lux @ (F1.6，AGC ON)
            黑白：0.01Lux @(F1.6，AGC ON)
            `,
          },
          {
            name: '水平旋转角度',
            standard: '360',
          },
          {
            name: '垂直旋转角度',
            standard: '-15°～90°',
          },
          {
            name: '工作温度',
            standard: '-20℃～50℃',
          },
          {
            name: '防护等级',
            standard: 'IP65',
          },
          {
            name: '外形尺寸',
            standard: '155mm×150mm×260mm（含电池）',
          },
          {
            name: '整体重量',
            standard: '5kg',
          },
        ],
      },
    ],
  },
}

export default data
