const data = {
  0: require('./0').default,
  1: require('./1').default,
  2: require('./2').default,
  3: require('./3').default,
  4: require('./4').default,
  5: require('./5').default,
  6: require('./6').default,
  7: require('./7').default,
  8: require('./8').default,
  9: require('./9').default,
  10: require('./10').default,
}

export default data
